export default function IconaEarnEC() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M9.17638 8.07848C11.8512 8.07848 14.0195 6.99431 14.0195 5.65691C14.0195 4.31952 11.8512 3.23535 9.17638 3.23535C6.50159 3.23535 4.33325 4.31952 4.33325 5.65691C4.33325 6.99431 6.50159 8.07848 9.17638 8.07848Z"
                stroke="#010101"
                strokeWidth="0.833333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.33325 9.69275C4.33325 11.0301 6.50159 12.1143 9.17638 12.1143"
                stroke="#010101"
                strokeWidth="0.833333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.33325 13.7288C4.33325 15.0662 6.50159 16.1503 9.17638 16.1503"
                stroke="#010101"
                strokeWidth="0.833333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M4.33325 5.65686V13.7287" stroke="#010101" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.0198 5.65686V7.67483" stroke="#010101" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M14.0194 12.7197C15.6655 12.7197 16.9998 12.0421 16.9998 11.2062C16.9998 10.3704 15.6655 9.69275 14.0194 9.69275C12.3734 9.69275 11.0391 10.3704 11.0391 11.2062C11.0391 12.0421 12.3734 12.7197 14.0194 12.7197Z"
                stroke="#010101"
                strokeWidth="0.833333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.0391 13.7288C11.0391 14.5646 12.3734 15.2422 14.0194 15.2422C15.6655 15.2422 16.9998 14.5646 16.9998 13.7288"
                stroke="#010101"
                strokeWidth="0.833333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.0391 16.2512C11.0391 17.0871 12.3734 17.7647 14.0194 17.7647C15.6655 17.7647 16.9998 17.0871 16.9998 16.2512"
                stroke="#010101"
                strokeWidth="0.833333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11.0391 11.2063V16.2512" stroke="#010101" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.9998 11.2063V16.2512" stroke="#010101" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
